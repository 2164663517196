"use client"
import React from 'react'
import styles from './DynamicButton.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

interface DynamicButtonProps {
    buttonType?: any,
    data?: any,
    label?: string,
    icon?: any,
    imageIcon?: string,
    isHaveBackground: boolean,
    isRounded: boolean,
    minWidth?: string,
    disabled?: boolean,
    url?: string,
    onClick?: (data?: any) => void

}

const DynamicButton = ({ buttonType, data, label, icon, imageIcon, isHaveBackground, isRounded, minWidth, disabled, url, onClick }: DynamicButtonProps) => {
    const navigate= useNavigate();

    const handleClick = () => {
        if (url) return navigate(url);
        if (onClick) {
            if (data) {
                onClick(data);
            } else {
                onClick();
            }
        }
    }

    return (
        <button type={buttonType ? buttonType : "button"} className={`${styles.btn} ${label ? styles.withLabel : styles.withoutLabel} ${isHaveBackground ? styles.withBackground : styles.withoutBackground} ${isRounded ? styles.rounded : styles.norounded}`} style={minWidth ? { minWidth: minWidth } : {}} disabled={disabled ? disabled : undefined} onClick={handleClick}>
            {
                icon &&
                <FontAwesomeIcon icon={icon} />
            }

            {
                imageIcon &&
                <img src={imageIcon} alt="" />
            }

            {
                label &&
                <span>{label}</span>
            }
        </button>
    )
}

export default DynamicButton