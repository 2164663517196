import React, { ChangeEvent } from 'react'
import styles from './SelectInput.module.css'

interface SelectInputProps {
    id: string;
    label:string;
    name: string;
    value: string;
    options: any[];
    onChange:(e:ChangeEvent<HTMLSelectElement>)=>void
}

const SelectInput = ({ id,label, name, value, options,onChange }: SelectInputProps) => {
    return (
        <div className={styles.container}>
            <label className={styles.label} htmlFor={id}>{label}</label>
            <select className={styles.select} name={name} id={id} value={value} onChange={onChange}>
                {
                    options.map((item, index) => (
                        <option key={index} value={item.value} title={item.description}>{item.label}</option>
                    ))
                }
            </select>
        </div>
    )
}

export default SelectInput