import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { lazy, Suspense, useEffect, useRef } from 'react';
import styles from './App.module.css'
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ShowMessage from './pages/message/ShowMessage';
import PageError from './pages/pageError/PageError';
import Loading from './pages/loading/Loading';
import Demo from './pages/demo/Demo';
import DriverSign from './pages/drivers/signin/SignIn';
import Docs from './pages/drivers/docs/Docs';
import Files from './pages/drivers/files/Files';
import ForgotPassword from './pages/drivers/forgotpassword/ForgotPassword';
import ResetPassword from './pages/drivers/resetpassword/ResetPassword';
import Support from './pages/support/Support';
import Drivers from './pages/drivers/Drivers';
import Driver from './pages/driver/Driver';
import { RegistrationProvider } from './contexts/RegistrationContext';
import Step1 from './pages/drivers/resgistration/step1/Step1';
import Step2 from './pages/drivers/resgistration/step2/Step2';
import Step3 from './pages/drivers/resgistration/step3/Step3';
import { Registration } from './pages/drivers/resgistration/Registration';
import DownloadIos from './pages/downloadios/DownloadIos';
import DownloadAndroid from './pages/downloadandroid/DownloadAndroid';

const Home = lazy(() => import('./pages/home/Home'));
const About = lazy(() => import('./pages/about/About'));
const Services = lazy(() => import('./pages/services/Services'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Team = lazy(() => import('./pages/team/Team'));
const PrivacyPolicies = lazy(() => import('./pages/privacypolicies/PrivacyPolicies'));
const TermOfuse = lazy(() => import('./pages/termsofuse/TermsOfUse'));
const Person = lazy(() => import('./pages/person/Person'));
const queryClient = new QueryClient();

const getDriverToken = () => {
  return localStorage.getItem('token');
}

const getUserToken = () => {
  return localStorage.getItem('accessToken');
}

function App() {
  const HomeLayout = () => {
    const { pathname } = useLocation();
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }, [pathname]);

    return (
      <div className={styles.main} ref={contentRef}>
        {/* <Demo isShow={pathname == "/"} /> */}
        <header className={styles.header}>
          <Header />
        </header>
        <main className={styles.content}>
          <Suspense fallback={<Loading />}>
            <QueryClientProvider client={queryClient}>
              <Outlet />
            </QueryClientProvider>
          </Suspense>
        </main>
        <footer className={styles.footer}>
          <Footer />
        </footer>
      </div>
    );
  };

  const DriverLayout = () => {
    const { pathname } = useLocation();
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }, [pathname]);

    return (
      <RegistrationProvider>
        <div className={styles.main} ref={contentRef}>
          {/* <header className={styles.header}>
          <DriverHeader />
        </header> */}
          <main className={styles.content}>
            <Suspense fallback={<Loading />}>
              <QueryClientProvider client={queryClient}>
                <Outlet />
              </QueryClientProvider>
            </Suspense>
          </main>
          {/* <footer className={styles.footer}>
          <DriverFooter />
        </footer> */}
        </div>
      </RegistrationProvider>

    );
  };


  const UserLayout = () => {
    const { pathname } = useLocation();
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }, [pathname]);

    return (
      <div className={styles.main} ref={contentRef}>
        {/* <header className={styles.header}>
          <DriverHeader />
        </header> */}
        <main className={styles.content}>
          <Suspense fallback={<Loading />}>
            <QueryClientProvider client={queryClient}>
              <Outlet />
            </QueryClientProvider>
          </Suspense>
        </main>
        {/* <footer className={styles.footer}>
          <DriverFooter />
        </footer> */}
      </div>
    );
  };

  const DriverAuthLayout: React.FC<{ element: JSX.Element }> = ({ element }) => {
    const token = getDriverToken();
    if (!token) {
      return <Navigate to="/driver/send-otp" replace />;
    }
    return element;
  };

  const ProtectedRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    if (!token) {
      return <Navigate to="/driver/registration" replace />;
    }
    return element;
  };

  // const UserAuthLayout: React.FC<{ element: JSX.Element }> = ({ element }) => {
  //   const token = getUserToken();
  //   if (!token) {
  //     return <Navigate to="/user/signin" replace />;
  //   }
  //   return element;
  // };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team/:name" element={<Person />} />
          <Route path="/support/:related" element={<Support />} />
        </Route>
        <Route path="/driver" element={<DriverAuthLayout element={<DriverLayout />} />}>
          <Route path="/driver" element={<Driver />} />
          <Route path="/driver/docs" element={<Docs />} />
          <Route path="/driver/files" element={<Files />} />
          <Route path="/driver/:step" element={<Registration />} />
        </Route>
        <Route path="/privacy&policies" element={<PrivacyPolicies />} />
        <Route path="/privacypolicies" element={<Navigate to={'/privacy&policies'} />} />
        <Route path="/termsofuse" element={<TermOfuse />} />
        <Route path="/alert" element={<ShowMessage />} />
        <Route path="/drivers" element={<Drivers />} />
        <Route path="/driver/signin" element={<DriverSign />} />
        <Route path="/driver/forgot-password" element={<ForgotPassword />} />
        <Route path="/driver/reset-password" element={<ResetPassword />} />
        <Route path="/driver/send-otp" element={<Step1 />} />
        <Route path="/driver/verify-otp" element={<Step2 />} />
        <Route path="/driver/setup-profile" element={<Step3 />} />
        <Route path="/*" element={<PageError />} />
        <Route path="/download/android" element={<DownloadAndroid />} />
        <Route path="/download/ios" element={<DownloadIos />} />

        {/* <Route path="/user" element={<UserAuthLayout element={<DriverLayout />} />}>
          <Route path="/user" element={<User />} />
          <Route path="/user/delete_account" element={<DeleteAccount />} />
        </Route>
        <Route path="/user/signin" element={<UserSignin />} />
        <Route path="/user/verify-otp" element={<UserVerifyOtp />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
