import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './Support.module.css'
import { accountSupport, bookingSupport } from '../../data'
import SEO from '../../SEO'

const Support = () => {
    const { related } = useParams()

    const supports: any = () => {
        switch (related) {
            case "account":
                return accountSupport;
            case "booking":
                return bookingSupport;
            default:
                { }
        }
    }

    return (
        <div className={styles.page}>
            <SEO title='Support' description='Supports' url='https://uracab.com/supports' image='https://uracab.com/services.jpeg' />
            <div className={styles.section}>
                {
                    supports()?.map((dd: any, index: number) => (
                        <div id={dd.id} className={styles.sectionItem1} key={index}>
                            <h1 className={styles.sectionTitle1}>{dd?.title}</h1>
                            <div className={styles.sectionList1}>
                                {
                                    dd?.sections.map((lis: any, index2: number) => (
                                        <div className={styles.sectionItem2} key={index2}>
                                            <h3 className={styles.sectionTitle2}>{lis?.title}</h3>
                                            {
                                                lis?.subsections &&
                                                <div className={styles.sectionItem3}>
                                                    <h4 className={styles.sectionTitl3}>{lis.subsections.title}</h4>
                                                    <div className={styles.sectionItem4}>
                                                        {
                                                            lis?.subsections?.sections.map((l: any, index3: number) => (
                                                                <p key={index3}>{l}</p>
                                                            ))
                                                        }

                                                    </div>
                                                </div>
                                            }
                                            {
                                                lis?.sections && lis?.sections.map((s: any, index4: number) => (
                                                    <p key={index4}>{s}</p>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Support