import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Step1 from './step1/Step1';
import Step2 from './step2/Step2';
import Step3 from './step3/Step3';
import Step4 from './step4/Step4';
import Step5 from './step5/Step5';
import Step6 from './step6/Step6';
import Step7 from './step7/Step7';

import styles from './Registration.module.css';
import { useRegistration } from '../../../contexts/RegistrationContext';




export const Registration = () => {
    const { step } = useParams()
    const profile = localStorage.getItem('user');
    const [parsedProfile, setProfile] = useState<any | null>(null);

    useEffect(() => {
        if (profile) {
            const parsed = JSON.parse(profile);
            setProfile(parsed);
        }
    }, [])

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.reload();
    }

    return (
        <div className={styles.page}>
            <div className={styles.heading}>
                <div>
                    <h2>{parsedProfile?.driver_name}</h2>
                    <p>{parsedProfile?.driver_ID}</p>
                </div>
                <button onClick={handleLogout}>Logout</button>
            </div>
            <div className={styles.spacer}></div>
            <RenderDoc step={step || ''} />
            <div className={styles.spacer}></div>
        </div>
    )
}

const RenderDoc: React.FC<{ step: string }> = ({ step }) => {
    if (!step) return null;
    // if (step === "send-otp") {
    //     return <Step1 />
    // }
    // if (step === "verify-otp") {
    //     return <Step2 />
    // }
    // if (step === "setup-profile") {
    //     return <Step3 />
    // }
    if (step === "add-vehicle") {
        return <Step4 />
    }
    if (step === "add-family-contacts") {
        return <Step5 />
    }
    if (step === "add-bank") {
        return <Step6 />
    }
    if (step === "upload-files") {
        return <Step7 />
    }
    if (step === "success") {
        return <Step7 />
    }
    return null
}
