import React, { useRef, useState } from 'react'
import styles from './Step2.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { ErrorType } from '../step1/Step1'
import DialogAlert from '../../../../components/dialogalert/DialogAlert'
import SEO from '../../../../SEO'

const Step2 = () => {
    const [otp, setOtp] = useState<string[]>(Array(4).fill(''));
    const [searchParams] = useSearchParams();
    const mobile = searchParams.get('mobile');
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [isLoading, setsIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | null>(null);
    const navigate = useNavigate();

    const handleChange = (value: string, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = value.replace(/\D/g, '');
        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
        setOtp(newOtp);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const validate = isGo();
        if (validate.mobile) {
            saveToServer({ otp: validate.otp, mobile: validate.mobile });
        }
    };

    const saveToServer = async (form: any) => {
        const token = localStorage.getItem("authToken");
        if (token) {
            const baseUrl = process.env.REACT_APP_API_URL;
            setsIsLoading(true);
            try {
                const response = await axios.post(baseUrl + "/api/auth/verify-otp", form,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const resToken = response.data?.token;
                localStorage.setItem('authToken', resToken);
                window.location.replace('/driver/setup-profile')
            } catch (error: any) {
                let err = error?.response?.data.errorMessage || "Internal server error";
                console.log(err);
                setError({ title: 'Error occured!', description: err });
                // if (err && err.code === "token_error") {
                //     window.location.replace("/driver/signin");
                // }
            } finally {
                setsIsLoading(false)
            }
        } else {
            setError({ title: 'Error occured!', description: "Token not found,please login again!" });
        }

    };

    const isGo = () => {
        let finalOtp = otp.filter(o => o !== "");
        if (finalOtp.length !== 4) {
            return {};
        }
        if (!mobile || mobile.length !== 10) {
            return {};
        }
        const myString = otp.join('');
        return { otp: myString, mobile };
    }

    return (
        <div className={styles.page}>
            <SEO title='Registration - Driver' description='Verify otp page for driver registration' url='https://uracab.com/driver/verify-otp' image='https://uracab.com/services.jpeg' />
            <div className={styles.spacer}></div>
            <div className={styles.section}>
                <h1>Verify your phone number</h1>
                <small>Enter 4 digits code sent to you at +9{mobile}</small>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.otp}>
                        {otp.map((digit, index) => (
                            <input
                                className={styles.input}
                                key={index}
                                type="text"
                                value={digit}
                                onChange={(e) => handleChange(e.target.value, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                maxLength={1}
                                ref={(ref) => (inputRefs.current[index] = ref)}
                            />
                        ))}
                    </div>
                    <div className={styles.buttons}>
                        <button type='button' className={styles.backBtn} onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={faAngleLeft} color='black' />
                        </button>
                        <button type='submit' className={styles.submitBtn} disabled={isGo().otp ? false : true}>
                            Next
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </form>
            </div>
            <div className={styles.spacer}></div>
            {
                error &&
                <DialogAlert
                    title={error.title}
                    description={error.description}
                    nobutton={{
                        label: 'Close',
                        onPress() {
                            setError(null);
                        },
                    }} />
            }
        </div>
    )
}

export default Step2