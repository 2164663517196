import React, { useState } from 'react';
import styles from './DownloadIos.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DialogAlert from '../../components/dialogalert/DialogAlert';

const androidUserScreens = [
    { title: 'Login Screen', description: '', image: '/user1.jpeg' },
    { title: 'Login Screen', description: '', image: '/user2.jpg' },
    { title: 'Login Screen', description: '', image: '/user3.jpg' },
    { title: 'Login Screen', description: '', image: '/user4.jpg' },
    { title: 'Login Screen', description: '', image: '/user5.jpg' },
    { title: 'Login Screen', description: '', image: '/user6.jpg' },
    { title: 'Login Screen', description: '', image: '/user7.jpg' },
    { title: 'Login Screen', description: '', image: '/user8.jpg' },
];

const stepsToInstallAndUseUraCab = {
    "Installation": [
        { step: 1, title: "Visit the Website", description: "Go to uracab.com." },
        { step: 2, title: "Download for iOS", description: "In the header section, click the iOS download option." },
        { step: 3, title: "Read Documentation", description: "Review the installation instructions carefully." },
        { step: 4, title: "Provide Email Address", description: "Enter a valid email address associated with your device." },
        { step: 5, title: "Receive Installation Link", description: "Check your email for a link from UraCab iOS support to install the app." },
        { step: 6, title: "Use TestFlight", description: "Open the email and click the 'View on TestFlight' link or download TestFlight from the App Store." },
        { step: 7, title: "Install the App", description: "Open TestFlight and install the UraCab app." }
    ],

    "Login and Registration": [
        { step: 8, title: "Open the App", description: "Launch UraCab and grant the necessary permissions:\n- Location: 'Allow UraCab to use your location.'\n- Notifications: 'Allow UraCab to send you notifications.'" },
        { step: 9, title: "Enter Phone Number", description: "Enter an active phone number to receive an OTP via SMS." },
        { step: 10, title: "New User Registration", description: "Provide a valid username and email. An OTP will be sent to verify your account. Wait up to 2 minutes for the OTP and avoid resending it repeatedly." },
        { step: 11, title: "Existing User Login", description: "Enter the OTP sent to your registered phone number. Wait patiently for the OTP and avoid resending it multiple times." }
    ],

    "Booking a Ride": [
        { step: 12, title: "Search for Cabs", description: "Tap the 'Search Cabs' button." },
        { step: 13, title: "Set Pickup Location", description: "Enter your pickup location manually or use the location marker to auto-select it." },
        { step: 14, title: "Set Drop-off Location", description: "Enter your drop-off location manually or use the location marker for automatic selection." },
        { step: 15, title: "Choose Ride Type", description: "Select from available ride options:\n- Ura Bike\n- Ura Car\n- Ura Premium" },
        { step: 16, title: "Retry if Needed", description: "If no drivers are available, click 'Retry' to search again." },
        { step: 17, title: "Connect with Driver", description: "Once a driver accepts your request, you'll be connected with them." },
        { step: 18, title: "Confirm Pickup Location", description: "Stay at your selected pickup point and allow the driver to locate you." },
        { step: 19, title: "Communicate with Driver", description: "Use the in-app call feature to contact the driver if needed." },
        { step: 20, title: "Share OTP with Driver", description: "Provide the OTP to the driver before starting the ride." },
        { step: 21, title: "Complete Payment", description: "Once you reach your destination, make the payment and rate the driver." },
        { step: 22, title: "Book Another Ride", description: "Repeat the process to book your next ride with UraCab!" }
    ]
};

function DownloadIos() {
    const navigate = useNavigate();
    const [alert, setAlert] = useState<{ title: string, description: string } | null>(null);
    const handleEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);
        console.log('FormDate:', formData.get('email'));
        const email = formData.get('email');
        if (email) {
            submitEmail(email as string);
        }
    }

    const submitEmail = async (email: string) => {
        try {
            const response = await axios.post('https://uragate.in/websites/webapi4/api/update-email', { email });
            console.log(response);
            setAlert({ title: 'Sucsess', description: 'Email submitted successfully!' });
        } catch (error: any) {
            console.log(error);
            const errorMessage = error.response.data.errorMessage || 'Internal server error';
            setAlert({ title: 'Failed!', description: errorMessage });
        }
    }

    return (
        <div className={styles.page}>
            {
                alert &&
                <DialogAlert
                    title={alert.title}
                    description={alert.description}
                    yesbutton={{
                        label: 'OKAY',
                        onPress: () => {
                            setAlert(null);
                        }
                    }} />
            }
            <div className={styles.heading}>
                <div className={styles.headingContainer}>
                    <FontAwesomeIcon icon={faAngleLeft} onClick={() => navigate(-1)} />
                    <h1>Download Application</h1>
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.notice}>
                    <h2>Coming soon on both "Google Play Store" and "App Store"</h2>
                    <div>
                        <img style={{ height: '70px', width: '70px', backgroundColor: 'white', borderRadius: '20px', padding: '5px' }} src="/google_play_store.png" alt="Google Play Store" />
                        <img style={{ height: '80px', width: '80px' }} src="/app_store.png" alt="App Store" />
                    </div>
                </div>
                <h2>Download iOS application</h2>
                <div className={styles.instructionContainer}>
                    {
                        Object.entries(stepsToInstallAndUseUraCab).map(([key, steps]) => (
                            <div className={styles.instructions} key={key}>
                                <h3>{key}</h3>
                                <div className={styles.instructionItems}>
                                    {steps.map((step, index) => (
                                        <p key={index} style={{ padding: '0px 20px' }}>
                                            <b>{index + 1}. {step.title}</b>: {step.description}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.app}>
                    <div className={styles.downloadBtnContainer}>
                        <div>
                            <img className={styles.appIcon} src="/userAppIcon.png" alt="App Icon" />
                            <div>
                                <h3>UraCab</h3>
                                <small>Download app for user</small>
                            </div>
                        </div>
                    </div>
                    <p>UraCab is an innovative online cab booking service...</p>

                    <div className={styles.screenshots}>
                        {androidUserScreens.map((screen, index) => (
                            <img key={index} className={styles.screenshot} src={screen.image} alt={`Screen ${index + 1}`} />
                        ))}
                    </div>
                </div>
                <form className={styles.emailInputContainer} onSubmit={handleEmailSubmit}>
                    <input type="email" id="email" name='email' placeholder="enter email address" />
                    <button type="submit">Submit email</button>
                </form>
            </div>
        </div>
    );
}

export default DownloadIos;
