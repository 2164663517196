import imageCompression, { Options } from "browser-image-compression";
import { PDFDocument } from "pdf-lib";

export const compressPDF = async (file: File, targetSizeMB: number): Promise<File> => {
    const targetSizeBytes = targetSizeMB * 1024 * 1024;
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    pdfDoc.setProducer('');
    pdfDoc.setCreator('');
    pdfDoc.setTitle('');

    let compressedPdfBytes = await pdfDoc.save({
        useObjectStreams: true,
        updateFieldAppearances: true,
    });

    if (compressedPdfBytes.length <= targetSizeBytes) {
        return new File([compressedPdfBytes], file.name, { type: 'application/pdf' });
    }

    // Iteratively attempt to compress further
    let compressionFactor = 0.5;
    while (compressedPdfBytes.length > targetSizeBytes && compressionFactor > 0.1) {
        compressionFactor -= 0.1;

        compressedPdfBytes = await pdfDoc.save({
            useObjectStreams: true,
            updateFieldAppearances: true,
        });

        if (compressedPdfBytes.length <= targetSizeBytes) {
            return new File([compressedPdfBytes], file.name, { type: 'application/pdf' });
        }
    }

    console.warn('Could not compress PDF to the target size. Returning the best effort.');
    return new File([compressedPdfBytes], file.name, { type: 'application/pdf' });
};



export const compressImageToTargetSize = async (
    file: File,
    targetSizeMB: number
): Promise<File> => {
    const targetSizeBytes = targetSizeMB * 1024 * 1024;
    let quality = 0.5;

    while (quality > 0.1) {
        const options: Options = {
            maxSizeMB: targetSizeMB,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            initialQuality: quality,
        };

        try {
            const compressedBlob = await imageCompression(file, options);
            if (compressedBlob.size <= targetSizeBytes) {
                return new File([compressedBlob], file.name, { type: compressedBlob.type });
            }
        } catch (error) {
            console.error('Error during image compression:', error);
            return file; 
        }

        quality -= 0.05;
    }

    console.warn('Could not compress image to the target size. Returning the best effort.');
    return new File([file], file.name, { type: file.type });
};