import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './Step3.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import StandardInput from '../../../../components/inputs/standardInput/StandardInput';
import axios from 'axios';
import DialogAlert from '../../../../components/dialogalert/DialogAlert';
import { ErrorType } from '../step1/Step1';
import { useRegistration } from '../../../../contexts/RegistrationContext';
import SEO from '../../../../SEO';
import SelectInput from '../../../../components/inputs/selectInput/SelectInput';

const fields = [
  { label: 'Firstname', name: 'first_name', inputType: 'text', placeholder: '' },
  { label: 'Lastname', name: 'last_name', inputType: 'text', placeholder: '' },
  { label: 'Date of birth', name: 'driver_dob', inputType: 'text', placeholder: 'dd-mm-yyyy' },
  { label: 'Email Address (Optional)', name: 'driver_email', inputType: 'text', placeholder: 'e.g example@gmail.com' },
  { label: 'Driver License', name: 'driving_license', inputType: 'text', placeholder: 'enter your driving license' },
];

const addressFields = [
  { label: 'Street Address', name: 'addressline', inputType: 'text', placeholder: '' },
  { label: 'Town/Village', name: 'city', inputType: 'text', placeholder: '' },
  { label: 'State', name: 'state', inputType: 'text', placeholder: '' },
  { label: 'Postal/Zip Code', name: 'zipcode', inputType: 'text', maxLength: 6, placeholder: '' },
];

const passwordFields = [
  { label: 'Password', name: 'password', inputType: 'password', isPassword: true, placeholder: 'enter new password' },
  { label: 'Re-enter Password', name: 're_password', inputType: 'text', placeholder: 're-enter password' },
];

const serviceTypes = [
  { label: '--Select service type--', value: '' },
  { label: 'City/Town', value: 'City' },
  { label: 'Outstation', value: 'Outstation' },
  { label: 'Both', value: 'Both' },
];

interface FormData {
  [key: string]: string;
}

const validateDate = (date: string) => {
  const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
  if (!regex.test(date)) {
    return false;
  }
  return true;
};

const Step3 = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({});
  const [error, setError] = useState<ErrorType | null>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    if (['driver_contact', 'zipcode'].includes(name) && !/^\d*$/.test(value)) return;

    const isBackspace = (event.nativeEvent as InputEvent).inputType === 'deleteContentBackward';
    if (name === 'driver_dob') {
      let input = value.replace(/\D/g, '');
      let formattedDate = '';

      if (input.length > 0) formattedDate += input.slice(0, 2);
      if (input.length >= 2 && (!isBackspace || (isBackspace && input.length > 2))) formattedDate += '-' + input.slice(2, 4);
      if (input.length >= 4 && (!isBackspace || (isBackspace && input.length > 4))) formattedDate += '-' + input.slice(4, 8);

      const dateParts = formattedDate.split('-');
      if (dateParts.length === 3) {
        const month = parseInt(dateParts[1], 10);
        const day = parseInt(dateParts[0], 10);

        if (month > 12 || day > 31) return;
      }
      return setFormData(prevData => ({ ...prevData, [name]: formattedDate }));
      return;
    }
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    const { addressline, city, zipcode, ...resData } = formData;
    if (!resData.first_name) return setError({ title: 'Error!', description: "First name is missing!" });
    if (!resData.last_name) return setError({ title: 'Error!', description: "Last name is missing!" });
    if (!resData.driver_dob) return setError({ title: 'Error!', description: "Date of birth is missing!" });
    if (!addressline || !city || !zipcode) return setError({ title: 'Error!', description: "Please enter all the fields in the address!" });
    if (!resData.password) return setError({ title: 'Error!', description: "Password is missing!" });
    if (!resData.re_password) return setError({ title: 'Error!', description: "Re enter password!" });
    if (resData.password !== resData.re_password) return setError({ title: 'Error!', description: "Password not matched!" });
    resData.driver_address = `${addressline},${city},Nagaland - ${zipcode}`
    resData.driver_name = `${resData.first_name} ${resData.last_name}`
    delete resData.re_password
    if (!validateDate(resData.driver_dob)) return setError({ title: 'Error!', description: "First name is missing!" });
    saveToServer(resData);
  };

  const saveToServer = async (form: any) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const baseUrl = process.env.REACT_APP_API_URL;
      setIsLoading(true);
      try {
        const response = await axios.post(baseUrl + "/api/drivers/register", form,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        const resToken = response.data?.token;
        localStorage.removeItem('authToken');
        localStorage.setItem('token', resToken);
        // navigate('/driver/registration2/add-vehicle');
        window.location.replace('/driver/add-vehicle');
      } catch (error: any) {
        let err = error?.response?.data.errorMessage || "Internal server error";
        console.log(err);
        setError({ title: 'Error occured!', description: err });
      } finally {
        setIsLoading(false)
      }
    } else {
      console.error("Token not available!");
      setError({ title: 'Error!', description: "Token not available!" });
    }
  };

  return (
    <div className={styles.page}>
      <SEO title='Registration - Driver' description='Registration page for driver registration' url='https://uracab.com/driver/setup-profile' image='https://uracab.com/services.jpeg' />
      <div className={styles.spacer}></div>
      <div className={styles.section}>
        <h1>Setup profile</h1>
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.formSection}>
            <p><strong>Profile</strong></p>
            <div className={styles.forms}>
              {fields.map((f, index) => (
                <div key={index} className={styles.inputContainer}>
                  <label htmlFor={f.name}>{f.label}</label>
                  <input
                    type={f.inputType}
                    placeholder={f.placeholder}
                    value={formData[f.name] || ''}
                    name={f.name}
                    onChange={handleInputChange}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={styles.formSection}>
            <p><strong>Address</strong></p>
            <div className={styles.forms}>
              {addressFields.map((a, index) => (
                <div key={index} className={styles.inputContainer}>
                  <label htmlFor={a.name}>{a.label}</label>
                  <input
                    type={a.inputType}
                    placeholder={a.placeholder}
                    value={a.name === 'state' ? 'Nagaland' : formData[a.name] || ''}
                    disabled={a.name === 'state'}
                    name={a.name}
                    maxLength={a.maxLength || 100}
                    onChange={handleInputChange}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={styles.formSection}>
            <p><strong>Preferred Service Type</strong></p>
            <div className={styles.forms}>
              <SelectInput
                id={'service_type'}
                label={'Service Type'}
                name={'service_type'}
                value={formData['service_type'] || ''}
                onChange={handleInputChange}
                options={serviceTypes} />
            </div>
          </div>

          <div className={styles.formSection}>
            <p><strong>Create Password</strong></p>
            <div className={styles.forms}>
              {passwordFields.map((a, index) => (
                <StandardInput
                  key={index}
                  id={a.name}
                  label={a.label}
                  name={a.name}
                  value={formData[a.name] || ''}
                  inputType={a.inputType}
                  isPassword={a.isPassword}
                  placeHolder={a.placeholder}
                  onChange={handleInputChange}
                />
              ))}
            </div>
          </div>

          <div className={styles.buttons}>
            <button disabled={isLoading} type='button' className={styles.backBtn} onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faAngleLeft} color='black' />
            </button>
            <button type='submit' disabled={isLoading} className={styles.submitBtn}>
              Next
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </form>
      </div>
      <div className={styles.spacer}></div>
      {
        error && error.description !== "Account already exist on this number!" &&
        <DialogAlert
          title={error.title}
          description={error.description}
          nobutton={{
            label: 'Close',
            onPress() {
              setError(null);
            },
          }} />
      }

      {
        error && error.description === "Account already exist on this number!" &&
        <DialogAlert
          title={"Account already exist"}
          description={error.description}
          nobutton={{
            label: 'Close',
            onPress() {
              setError(null);
            },
          }}
          yesbutton={{
            label: "Login now",
            onPress() {
              window.location.replace('/driver/signin');
            },
          }}
        />
      }
    </div>
  );
};

export default Step3;
