import React from 'react'
import { Helmet } from 'react-helmet-async';
interface SEOProps {
    title: string;
    description: string;
    image?: string;
    url: string
}

const SEO: React.FC<SEOProps> = ({ title,description, image, url }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href={url} />

            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            {image && <meta property='og:image' content={image} />}
            <meta property='og:url' content={url} />
            <meta property='og:type' content='website' />

            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} />
            {image && <meta name='twitter:image' content={image} />}
        </Helmet>
    )
}

export default SEO