import React from 'react'
import styles from './Address.module.css'

function Address() {
  return (
    <section className={styles.container}>
      <h2 className={styles.headerTitle}>Address</h2>
      <address className={styles.address}>UraCab Pvt. Ltd. New Secretariat Road, Capital Convention Centre, IDAN, Kohima, Nagaland , India - 797001.</address>
      <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "PostalAddress",
          "streetAddress": "Capital Convention,Idan,New Secretariat Road",
          "addressLocality": "Kohima",
          "addressRegion": "Nagaland",
          "postalCode": "797001",
          "addressCountry": "IN"
        })}
      </script>
    </section>
  )
}

export default Address