import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faArrowRight,
    faCircleCheck,
    faCloudArrowUp,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import styles from './Step7.module.css';
import DialogAlert from '../../../../components/dialogalert/DialogAlert';
import { compressImageToTargetSize, compressPDF } from '../../../../fileCompression';
import { ErrorType } from '../step1/Step1';
import SEO from '../../../../SEO';

export const fields = [
    { document_name: "Passport Photo", document_type: "passport", description: "File format should be in JPG,JPEG and the size should not exceed 3 MB." },
    { document_name: "Permanent Account Number (PAN)", document_type: "pan", description: "The file format should be PDF and the size should not exceed 3 MB." },
    { document_name: "Driving License", document_type: "driving_license", description: "The file format should be PDF and the size should not exceed 3 MB." },
    { document_name: "Address Proof (PRC)", document_type: "prc", description: "The file format should be PDF and the size should not exceed 3 MB." },
    { document_name: "Bank Account", document_type: "bank", description: "The file format should be PDF and the size should not exceed 3 MB." },
    { document_name: "Registration Certificate (RC) of the vehicle", document_type: "vehicle_rc", description: "The file format should be PDF and the size should not exceed 3 MB." },
    { document_name: "Fitness Certificate of the vehicle", document_type: "vehicle_fitness", description: "The file format should be PDF and the size should not exceed 3 MB." },
    { document_name: "Permit of the vehicle", document_type: "vehicle_permit", description: "The file format should be PDF and the size should not exceed 3 MB." },
    { document_name: "Government verified document", document_type: "epic", description: "The file format should be PDF and the size should not exceed 3 MB." },
];

interface FileForm {
    document_name: string;
    document_type: string;
    description: string;
    path?: string;
    status?: UploadStatus;
}

enum UploadStatus {
    Initial = 'initial',
    Loading = 'loading',
    Failed = 'failed',
    Loaded = 'loaded',
}

const Step7: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const { files, setFiles } = useRegistration();
    const [files, setFiles] = useState<FileForm[]>(fields);
    const [error, setError] = useState<ErrorType | null>(null);
    const throttle = useRef<boolean>(false);

    useEffect(() => {
        if (!throttle.current) {
            fetchFiles();
            throttle.current = true;
        }
    }, []);

    const fetchFiles = async () => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        const baseUrl = process.env.REACT_APP_API_URL;

        let driverId = "";

        if (!token) {
            handleTokenError();
            return;
        }

        if (user) {
            const parsed = JSON.parse(user);
            driverId = parsed.driver_ID;
        } else {
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/api/documents/file/${driverId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            mergeData(response.data.files);
        } catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || "Internal server error";
            console.log(error.response.data);
            if (errorMessage === "read ECONNRESET") {
                setError({ title: "Error occurred!", description: "Something went wrong, please reload the page" });
                return;
            }
            setError({ title: "Error occurred!", description: errorMessage });
        } finally {
            setIsLoading(false);
        }
    };

    const mergeData = (data: any[]) => {
        const updatedFiles = files.map((item) => {
            const match = data.find((d) => d.document_type === item.document_type);
            return {
                ...item,
                path: match?.path || '',
                status: match ? UploadStatus.Loaded : UploadStatus.Initial,
            };
        });
        setFiles(updatedFiles);
    };

    const handleTokenError = () => {
        setError({ title: "Error occurred!", description: "Token not found!" });
        window.location.replace("/driver/signin");
    };

    const updateStatus = (status: UploadStatus, name: string) => {
        setFiles(
            files.map((file) =>
                file.document_type === name ? { ...file, status } : file
            )
        );
    };

    const updateFile = (da: any) => {
        setFiles(
            files.map((file) =>
                file.document_type === da.document_type ? {
                    ...file,
                    path: da.path,
                    status: da.status
                } : file
            )
        );
    };

    return (
        <div className={styles.section}>
            <h1>Upload files</h1>
            <div className={styles.form}>
                <div className={styles.forms}>
                    {files.map((file, index) => (
                        <UploadElement
                            key={index}
                            {...file}
                            uploadStatus={file.status || UploadStatus.Initial}
                            updateStatus={updateStatus}
                            updateData={updateFile}
                        />
                    ))}
                </div>
                <div className={styles.buttons}>
                    <button
                        disabled={isLoading}
                        className={styles.backBtn}
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <div style={{ flex: 1 }}></div>
                    <button disabled={isLoading} className={styles.submitBtn} onClick={() => window.location.href = '/driver'}>
                        Done <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>
            </div>
            {error && (
                <DialogAlert
                    title={error.title}
                    description={error.description}
                    nobutton={{
                        label: "Close",
                        onPress: () => setError(null),
                    }}
                />
            )}
        </div>
    );
};

interface UploadElementProps {
    document_name: string;
    description: string;
    document_type: string;
    path?: string;
    uploadStatus: UploadStatus;
    updateStatus: (status: UploadStatus, name: string) => void;
    updateData: (data: any) => void
}

const UploadElement: React.FC<UploadElementProps> = ({
    document_name,
    description,
    document_type,
    path,
    uploadStatus,
    updateStatus,
    updateData,
}) => {
    const [error, setError] = useState<ErrorType | null>(null);

    const handleOpenClick = () => {
        if (path) {
            const url = `https://uragate.in/websites/webapi4/file/${path}`;
            window.open(url, "_blank");
        }
    };

    const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        const name = e.target.name;
        console.log(name);
        if (!file) return;
        const isValidSize = file.size <= 3 * 1024 * 1024;
        const isValidType =
            document_type === "passport" ? file.type === "image/jpeg" : file.type === "application/pdf";

        if (!isValidSize || !isValidType) {
            setError({
                title: "Invalid file!",
                description: `File should be ${document_type === "passport" ? "JPG" : "PDF"} and under 3 MB.`,
            });
            return;
        }

        updateStatus(UploadStatus.Loading, document_type);
        const token = localStorage.getItem("token");
        const baseUrl = process.env.REACT_APP_API_URL;

        try {
            const compressedFile =
                document_type === "passport"
                    ? await compressImageToTargetSize(file, 1)
                    : await compressPDF(file, 1);

            console.log(compressedFile);
            const response = await axios.post(baseUrl + "/api/documents/file", { name: document_name, type: name, file: compressedFile },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log(response);
            const path = response.data.path;
            updateData({ document_type, path, status: UploadStatus.Loaded });
            // updateData(response.data);
        } catch (error) {
            updateStatus(UploadStatus.Failed, document_type);
            setError({ title: "Upload failed!", description: "Please try again." });
        }
    };


    const removeFile = async () => {
        const token = localStorage.getItem("token");
        if (!token || !path) {
            console.error("Token or uploadedPath is missing!");
            return;
        }

        if (!token) {
            console.error("Parsed token is invalid!");
            return;
        }

        try {
            updateStatus(UploadStatus.Loading, document_type);
            const baseUrl = process.env.REACT_APP_API_URL;
            const response = await axios.delete(baseUrl +
                `/api/documents/file?type=${document_type}&path=${path}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log(response);
            updateData({ document_type, path: '', status: UploadStatus.Initial });
        } catch (error: any) {
            console.error("Error removing file:", error);

            const err = error?.response?.data;
            if (err && err.code === "token_error") {
                window.location.replace("/driver/signin");
            } else {
                console.error("An unexpected error occurred:", err);
            }
            updateStatus(UploadStatus.Failed, document_type);
        }
    };


    const acceptFile = document_type === "passport" ? "image/jpeg" : "application/pdf"

    return (
        <div className={styles.formSection}>
            <SEO title='Upload files - Driver' description='Upload files page for driver registration' url='https://uracab.com/driver/upload-files' image='https://uracab.com/services.jpeg' />
            <p>
                <strong>{document_name}</strong>
            </p>
            {path ? (
                <div className={`${styles.pickBtnAfter}`} style={{ borderColor: 'green' }}>
                    <FontAwesomeIcon icon={faCircleCheck} color="green" />
                    <strong>Uploaded</strong>
                    <small>{document_name} uploaded successfully!</small>
                    <div className={styles.itemButtons}>
                        <button
                            className={`${styles.itemBtn} ${styles.itemBtnRemove}`}
                            onClick={removeFile}
                        >
                            Remove
                        </button>
                        <button
                            className={`${styles.itemBtn} ${styles.itemBtnView}`}
                            onClick={handleOpenClick}
                        >
                            Open
                        </button>
                    </div>
                </div>
            ) : (
                <div className={styles.pickBtnContainer}>
                    <label
                        className={`${styles.pickBtn} ${uploadStatus === UploadStatus.Failed ? styles.uploadFailed : ''
                            }`}
                    >
                        <FontAwesomeIcon icon={faCloudArrowUp} />
                        <strong>{uploadStatus === UploadStatus.Failed ? 'Retry' : 'Browse File'}</strong>
                        <small>{description}</small>
                        <input type="file" accept={acceptFile} name={document_type} hidden onChange={handleInputChange} />
                    </label>
                    {
                        uploadStatus === UploadStatus.Loading &&
                        <div className={styles.uploadingContainer}>
                            <FontAwesomeIcon icon={faSpinner} spin />
                            <small>Uploading.....</small>
                        </div>
                    }
                </div>
            )}
            {error && (
                <DialogAlert
                    title={error.title}
                    description={error.description}
                    nobutton={{
                        label: "Close",
                        onPress: () => setError(null),
                    }}
                />
            )}
        </div>
    );
};

export default Step7;
