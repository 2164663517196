import React, { ChangeEvent, useState } from 'react'
import styles from './Step6.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import SelectInput from '../../../../components/inputs/selectInput/SelectInput'
import StandardInput from '../../../../components/inputs/standardInput/StandardInput'
import axios from 'axios'
import DialogAlert from '../../../../components/dialogalert/DialogAlert'
import { ErrorType } from '../step1/Step1'
import { useRegistration } from '../../../../contexts/RegistrationContext'
import SEO from '../../../../SEO'

const banks = [
    { label: "--Select vehicle type--", value: '' },
    { label: "State Bank of India", value: 'SBI' },
    { label: "Bank of Baroda", value: 'BOB' },
    { label: "Axis Bank", value: 'Axis' },
    { label: "HDFC Bank", value: 'HDFC' },
    { label: "ICICI Bank", value: 'ICICI' },
    { label: "Bank of India", value: 'BOI' },
    { label: "Reserve Bank of India", value: 'RBI' },
    { label: "IDBI Bank", value: 'IDBI' },
    { label: "Nagaland Rural Bank", value: 'NRB' },
    { label: "North East Small Finance Bank", value: 'NESF' },
    { label: "Federal Bank", value: 'Federal' },
    { label: "Nagaland State Co-operative Bank", value: 'NSC' },
    { label: "Bandhan", value: 'BOI' },
    { label: "UCO Bank", value: 'UCO' },
    { label: "Union Bank of India", value: 'Union' },
    { label: "Central Bank of India", value: 'CBI' },
    { label: "IndusInd Bank", value: 'Induslnd' },
    { label: "Canara Bank", value: 'Canara' },
]

const fields = [
    { label: "Account number", name: "account_no", inputType: "text", placeholder: "bank account number" },
    { label: "Account holder name", name: "holder_name", inputType: "text", placeholder: 'enter account holder name' },
    { label: "Branch", name: "branch", inputType: "text", placeholder: "branch name" },
    { label: "IFSC Code", name: "ifsc", inputType: "text", placeholder: 'ifsc code' },
    { label: "Bank", name: "bank_name", inputType: "text", placeholder: 'bank name' },

];


const Step6 = () => {
    const navigate = useNavigate();
    const { bank, setBank } = useRegistration();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | null>(null);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        if (name === "account_no" && !/^\d*$/.test(value)) return
        setBank({ ...bank, [name]: value });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!bank.account_no) return setError({ title: 'Error occured!', description: "Account number is missing!" });
        if (!bank.holder_name) return setError({ title: 'Error occured!', description: "Account holder name is missing!" });
        if (!bank.branch) return setError({ title: 'Error occured!', description: "Branch name is missing!" });
        if (!bank.ifsc) return setError({ title: 'Error occured!', description: "IFSC is missing!" });
        if (!bank.bank_name) return setError({ title: 'Error occured!', description: "Bank name is missing!" });
        saveToServer(bank);
        // setsIsLoading(true);
    }

    const saveToServer = async (form: any) => {
        const token = localStorage.getItem("token");
        if (token) {
            const baseUrl = process.env.REACT_APP_API_URL;
            setIsLoading(true);
            try {
                const response = await axios.post(baseUrl + "/api/documents?docType=bank", form,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log(response.data);
                navigate('/driver/upload-files');
                // window.location.href = "/driver/upload-files";
            } catch (error: any) {
                let err = error?.response?.data.errorMessage || "Internal server error";
                console.log(err);
                setError({ title: 'Error occured!', description: err });
                // if (err && err.code === "token_error") {
                //     window.location.replace("/driver/signin");
                // }
            } finally {
                setIsLoading(false)
            }
        } else {
            console.error("Token not available!");
            setError({ title: 'Error occured!', description: "Token not found!" });
        }
    };
    return (
        <div className={styles.section}>
            <SEO title='Add bank - Driver' description='Add bank account page for driver registration' url='https://uracab.com/driver/add-bank' image='https://uracab.com/services.jpeg' />

            <h1>Add Bank Account</h1>
            <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.formSection}>
                    {/* {
                        fields.map((v, index) => v.inputType === "select" ? (
                            <SelectInput key={index} id={v.name} label={v.label} name={v.name} value={bank[v.name] || ''} options={v.options || []} onChange={handleInputChange} />
                        ) : (
                            <StandardInput key={index} id={v.name} label={v.label} name={v.name} value={bank[v.name] || ''} inputType={v.inputType} placeHolder={v.placeholder} onChange={handleInputChange} />
                        ))
                    } */}
                    {
                        fields.map((v, index) => (
                            <StandardInput key={index} id={v.name} label={v.label} name={v.name} value={bank[v.name] || ''} inputType={v.inputType} placeHolder={v.placeholder} onChange={handleInputChange} />

                        ))
                    }
                </div>
                <small>If you're having trouble adding this document, you can skip it for now, contact customer support, and fill it out later.</small>

                <div className={styles.buttons}>
                    <button disabled={isLoading} type='button' className={styles.backBtn} onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} color='black' />
                    </button>
                    <div style={{ flex: 1 }}></div>
                    <button type='button' disabled={isLoading} className={styles.skipBtn} onClick={() => navigate('/driver/upload-files')}>
                        Skip
                    </button>
                    <button type='submit' disabled={isLoading} className={styles.submitBtn}>
                        Next
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>
            </form>
            {
                error &&
                <DialogAlert
                    title={error.title}
                    description={error.description}
                    nobutton={{
                        label: 'Close',
                        onPress() {
                            setError(null);
                        },
                    }} />
            }
        </div>
    )
}

export default Step6