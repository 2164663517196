import React, { useState } from 'react'
import styles from './DownloadAndroid.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

const androidUserScreens = [
    { title: 'Login Screen', description: '', image: '/user1.jpeg' },
    { title: 'Login Screen', description: '', image: '/user2.jpg' },
    // { title: 'Login Screen', description: '', image: '/user3.jpg' },
    { title: 'Login Screen', description: '', image: '/user4.jpg' },
    { title: 'Login Screen', description: '', image: '/user5.jpg' },
    { title: 'Login Screen', description: '', image: '/user6.jpg' },
    { title: 'Login Screen', description: '', image: '/user7.jpg' },
    { title: 'Login Screen', description: '', image: '/user8.jpg' },
]

const androidDriverScreens = [
    { title: 'Login Screen', description: '', image: '/driver1.jpeg' },
    { title: 'Login Screen', description: '', image: '/driver2.jpeg' },
    { title: 'Login Screen', description: '', image: '/driver3.jpeg' },
    { title: 'Login Screen', description: '', image: '/driver4.jpeg' },
    { title: 'Login Screen', description: '', image: '/driver5.jpeg' },
    { title: 'Login Screen', description: '', image: '/driver6.jpeg' },
    { title: 'Login Screen', description: '', image: '/driver7.jpeg' },
    { title: 'Login Screen', description: '', image: '/driver8.jpeg' },
    { title: 'Login Screen', description: '', image: '/driver9.jpeg' },
]

const installationSteps = [
    'Download the APK File from the given link below',
    'Open your File Manager app or go to your Downloads folder.',
    'Tap on the APK file you downloaded.',
    'Warning: You may see a pop-up message that says, "This type of file can harm your device." This is a standard security warning for installing apps outside the Google Play Store. As long as you downloaded the APK from a trusted source (such as our official website or the link we provided), the file is safe to install.',
    'Tap Install to begin the installation process.',
]

const userAppfeatures = {
    "Key Features": [
        {
            title: "Safety and Reliability",
            description: "All drivers are carefully vetted and trained to ensure a safe journey."
        },
        {
            title: "Real-Time Tracking",
            description: "Stay informed with live updates on your driver’s location and estimated arrival time."
        },
        {
            title: "Multiple Ride Options",
            description: "Choose from 2-wheeler cabs, mini cabs, and premium cabs, catering to different needs and preferences."
        },
        {
            title: "Payment Methods",
            description: "Pay via cash or digital payment methods for added convenience."
        },
        {
            title: "Customer Support",
            description: "Dedicated assistance is available for any queries or issues throughout your journey."
        }
    ],
    "Services Offered": [
        {
            title: "Standard Rides",
            description: "Ideal for everyday commuting or quick city trips."
        },
        {
            title: "Airport Transfers",
            description: "Timely and reliable transportation to and from the airport."
        },
        {
            title: "Rental Services",
            description: "Rent a cab for a few hours or a full day for sightseeing or special events."
        }
    ],
    "How To Book": [
        { step: 12, title: "Search for Cabs", description: "Tap the 'Search Cabs' button." },
        { step: 13, title: "Set Pickup Location", description: "Enter your pickup location manually or use the location marker to auto-select it." },
        { step: 14, title: "Set Drop-off Location", description: "Enter your drop-off location manually or use the location marker for automatic selection." },
        { step: 15, title: "Choose Ride Type", description: "Select from available ride options:\n- Ura Bike\n- Ura Car\n- Ura Premium" },
        { step: 16, title: "Retry if Needed", description: "If no drivers are available, click 'Retry' to search again." },
        { step: 17, title: "Connect with Driver", description: "Once a driver accepts your request, you'll be connected with them." },
        { step: 18, title: "Confirm Pickup Location", description: "Stay at your selected pickup point and allow the driver to locate you." },
        { step: 19, title: "Communicate with Driver", description: "Use the in-app call feature to contact the driver if needed." },
        { step: 20, title: "Share OTP with Driver", description: "Provide the OTP to the driver before starting the ride." },
        { step: 21, title: "Complete Payment", description: "Once you reach your destination, make the payment and rate the driver." },
        { step: 22, title: "Book Another Ride", description: "Repeat the process to book your next ride with UraCab!" }
    ],
    "Benefits": [
        {
            title: "Safe and Reliable",
            description: "All drivers undergo thorough vetting and training for your safety."
        },
        {
            title: "Convenient Booking",
            description: "The user-friendly app and website make booking easy and fast."
        },
        {
            title: "Transparency",
            description: "Real-time tracking provides complete transparency, so you know exactly when your ride will arrive."
        },
        {
            title: "Affordable Rates",
            description: "Competitive pricing and fare estimates ensure that you get the best value for your money."
        },
        {
            title: "Support",
            description: "Our dedicated customer service team is available to assist you with any questions or concerns."
        }
    ],
    // "Stay Connected": {
    //     email: "[email]",
    //     "Social Media": {
    //         facebook: "@UraCab",
    //         instagram: "@ura_cab",
    //         x: "@ura_cab"
    //     }
    // },
    // "Company Commitment": "UraCab is committed to making your travel experience in Kohima smooth, convenient, and hassle-free."
};



const ipaDownloadUrl = 'http://uragate.in/websites/webapi4/ios/download';
// const userAndroidUrl = "https://uracab.com/android/uracab.apk"
const userAndroidUrl = "https://play.google.com/store/apps/details?id=com.uracabtest"
const driverAndroidUrl = "https://uracab.com/android/uracabdriver.apk"


function DownloadAndroid() {
    const navigate = useNavigate();
    const [expandDesc, setExpandDesc] = useState<boolean>(false);

    const handleDownloadClick = (url: string) => {
        window.location.href = url;
    }
    return (
        <div className={styles.page}>
            <div className={styles.heading}>
                <div className={styles.headingContainer}>
                    <FontAwesomeIcon icon={faAngleLeft} onClick={() => navigate(-1)} />
                    <h1>Download Application</h1>
                </div>
            </div>
            <div className={styles.section}>
                {/* <div className={styles.notice}>
                    <h2>Comming soon on "Google Play Store"</h2>
                    <div>
                        <img style={{ height: '70px', width: '70px', backgroundColor: 'white', borderRadius: '20px',padding:'5px'}} src="/google_play_store.png" alt="Google Play Store" />
                    </div>
                </div> */}
                <h2 style={{ margin: 0 }}>Download Android application</h2>
                {/* <h2 style={{ margin: 0 }}>Android application</h2> */}
                <div className={styles.instructionContainer}>
                    <h3>How to Install UraCab and UraCab Driver App on Android via APK</h3>
                    <div className={styles.installationSteps}>
                        {
                            installationSteps.map((i, index) => (
                                <p className={styles.step} key={index}>{i}</p>
                            ))
                        }
                    </div>
                    <h3>Open the App</h3>
                    <p>Once the installation is complete, you can open the app directly from the installation screen, or find it in your Apps menu.</p>
                </div>
                <div className={styles.app}>
                    <div className={styles.downloadBtnContainer}>
                        <div>
                            <img className={styles.appIcon} src="/driverAppIcon.png" alt="" />
                            <div>
                                <h3>UraCab Driver</h3>
                                <small>Download app for driver</small>
                            </div>
                        </div>
                        <button className={styles.downloadBtn} onClick={() => handleDownloadClick(driverAndroidUrl)}>Download</button>
                    </div>
                    <div className={styles.instructionContainer}>
                        <h3>Important Notice for All Drivers:</h3>
                        <h4 style={{ fontWeight: '500', lineHeight: '22px' }}>Please be advised that after you have completed your registration with UraCab, it is mandatory to contact the UraCab office in order to activate your account or receive confirmation of your registration. This step ensures that your profile is fully set up and ready for you to begin accepting ride requests.</h4>
                        <h4>Steps to Activate Your Account:</h4>
                        <div className={styles.installationSteps}>
                            <p className={styles.step}>Register your details with UraCab.</p>
                            <p className={styles.step}>Call the <a href='/contact'>UraCab office</a> to confirm and activate your account..</p>
                        </div>
                        <p>Failure to complete this step will result in your account remaining inactive. If you have any questions or require assistance, don't hesitate to <a href='/contact'> contact our office</a></p>
                    </div>
                    <div className={styles.screenshots}>
                        {
                            androidDriverScreens.map((a, index) => (
                                <img className={styles.screenshot} key={index} src={a.image} alt="" />
                            ))
                        }
                    </div>

                </div>

                {/* <div className={styles.app}>
                    <div className={styles.downloadBtnContainer}>
                        <div>
                            <img className={styles.appIcon} src="/userAppIcon.png" alt="" />
                            <div>
                                <h3>UraCab</h3>
                                <small>Download app for user</small>
                            </div>
                        </div>
                        <button className={styles.downloadBtn} onClick={() => handleDownloadClick(userAndroidUrl)}>Download</button>
                    </div>
                    <p style={{ padding: '0px 20px', margin: 0, }}>UraCab is an innovative online cab booking service  providing a seamless and efficient transportation solution for locals and visitors alike in and around Nagaland. With a user-friendly interface, customers can easily book rides via the UraCab website or mobile app. Whether you're commuting around the city, heading to the airport, UraCab ensures a smooth and reliable travel experience.</p>
                    <p style={{ color: 'blue', margin: 0, padding: '0px 20px' }} onClick={() => setExpandDesc(!expandDesc)}>{expandDesc ? 'Hide details' : 'Read more'}</p>
                    <div className={styles.appfeatures}>
                        {
                            expandDesc && Object.entries(userAppfeatures).map(([key, value]: [string, any]) => (
                                <div className={styles.featureItem} key={key}>
                                    <h3 style={{ margin: 0 }}>{key}</h3>
                                    <div className={styles.features}>
                                        {value &&
                                            value?.map((i: any, index: number) => (
                                                <div className={styles.feature}>
                                                    <p>  <b>{index + 1}. {i?.title}</b></p>
                                                    <p>{i.description}</p>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        <div className={styles.featureItem}>
                            <h4 style={{ margin: 0 }}>Stay Connected</h4>
                            <p>
                                support@uracab.com
                            </p>
                        </div>
                    </div>
                    <div className={styles.screenshots}>
                        {
                            androidUserScreens.map((a, index) => (
                                <img className={styles.screenshot} key={index} src={a.image} alt="" />
                            ))
                        }
                    </div>
                </div> */}

            </div>


        </div>
    )
}

export default DownloadAndroid