import { useState } from 'react'
import styles from './Header.module.css'
import { Link, useLocation } from 'react-router-dom';
import HamburgerMenu from '../hamburger/HamburgerMenu';
import { links } from '../../data';


function Header() {
    const { pathname } = useLocation();
    const [isMenuOpened, setMenuOpened] = useState<boolean>(false);
    return (
        <div className={styles.container}>
            <Link to={'/'} className={`${styles.logo} ${isMenuOpened ? styles.hideLogo : ""}`}>
                <img src="https://uracab.com/logo.png" alt="uracab logo" height={32} width={32} title='UraCab logo' />
                <span>UraCab</span>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "ImageObject",
                        "name": "UraCab logo",
                        "description": "Logo of UraCab Private Limited",
                        "contentUrl": "https://uracab.com/logo.png",
                        "image": "https://uracab.com/logo.png",
                        "author": {
                            "@type": "Person",
                            "name": "Owned by Pangkam D Sangma, Generated by Gemini Ai"
                        },
                        "width": 32,
                        "height": 32,
                    })}
                </script>
            </Link>
            <ul className={`${styles.links} ${isMenuOpened ? styles.isOpened : ""}`}>
                {
                    links.map((link, index) => (
                        <li key={index}>
                            <Link to={link.url} className={`${styles.link} ${pathname == link.url ? styles.activeUrl : ''}`} onClick={() => setMenuOpened(!isMenuOpened)}>{link.label}</Link>
                        </li>
                    ))
                }
            </ul>
            <div className={styles.toggleBtn} onClick={() => setMenuOpened(!isMenuOpened)}>
                <HamburgerMenu isOpened={isMenuOpened} />
            </div>
        </div>
    )
}

export default Header