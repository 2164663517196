import React, { ChangeEvent, useState } from 'react'
import styles from './Step5.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import StandardInput from '../../../../components/inputs/standardInput/StandardInput'
import axios from 'axios'
import { ErrorType } from '../step1/Step1'
import DialogAlert from '../../../../components/dialogalert/DialogAlert'
import { useRegistration } from '../../../../contexts/RegistrationContext'
import SEO from '../../../../SEO'

const fields = [
    { label: "Name", name: "name", inputType: "text", placeholder: "enter name" },
    { label: "Address", name: "address", inputType: "text", placeholder: "enter address" },
    { label: "Contact number", name: "contact", inputType: "text", placeholder: "enter contact number" },
];


const Step5 = () => {
    const navigate = useNavigate();
    const { familyContacts, setFamilyContacts } = useRegistration();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | null>(null);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        if (['contact1', 'contact2'].includes(name) && !/^\d*$/.test(value)) return;
        setFamilyContacts({ ...familyContacts, [name]: value });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!familyContacts.name1) return setError({ title: 'Error occured!', description: "Name of first family member is missing!" });
        if (!familyContacts.name2) return setError({ title: 'Error occured!', description: "Name of second family member is missing!" });
        if (!familyContacts.address1) return setError({ title: 'Error occured!', description: "Address of first family member is missing!" });
        if (!familyContacts.address2) return setError({ title: 'Error occured!', description: "Address of second family member is missing!" });
        if (!familyContacts.contact1) return setError({ title: 'Error occured!', description: "Contact of first family member is missing!" });
        if (!familyContacts.contact2) return setError({ title: 'Error occured!', description: "Contact of second family member is missing!" });
        saveToServer(familyContacts);
    }


    const saveToServer = async (form: any) => {
        const token = localStorage.getItem("token");
        if (token) {
            const baseUrl = process.env.REACT_APP_API_URL;
            setIsLoading(true);
            try {
                const response = await axios.post(baseUrl + "/api/documents?docType=family_contact", form,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log(response.data);
                navigate('/driver/add-bank');
                // window.location.replace('/driver/add-bank');
            } catch (error: any) {
                let err = error?.response?.data.errorMessage || "Internal server error";
                console.log(err);
                setError({ title: 'Error occured!', description: err });
                // if (err && err.code === "token_error") {
                //     window.location.replace("/driver/signin");
                // }
            } finally {
                setIsLoading(false)
            }
        } else {
            console.error("Token not available!");
            setError({ title: 'Error occured!', description: "Token not found!" });
        }
    };

    return (
        <div className={styles.section}>
            <SEO title='Family contacts - Driver' description='Family contacts and addresses of drivers' url='https://uracab.com/driver/add-family-contacts' image='https://uracab.com/services.jpeg' />

            <h1>Add family contacts and addresses</h1>
            <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.formSection}>
                    <p><strong>First</strong></p>
                    <div className={styles.forms}>
                        {
                            fields.map((f, index) => (
                                <div key={index} className={styles.inputContainer}>
                                    <label htmlFor="">{f.label}</label>
                                    <input type="text" placeholder={f.placeholder} name={f.name + "1"} value={familyContacts[f.name + "1"] || ''} onChange={handleInputChange} maxLength={f.name === "contact" ? 10 : 200} />
                                </div>
                            ))
                        }
                    </div>

                </div>

                <div className={styles.formSection}>
                    <p><strong>Second</strong></p>
                    <div className={styles.forms}>
                        {
                            fields.map((a, index) => (
                                <div key={index} className={styles.inputContainer}>
                                    <label htmlFor="">{a.label}</label>
                                    <input type="text" placeholder={a.placeholder} name={a.name + "2"} value={familyContacts[a.name + "2"] || ''} onChange={handleInputChange} maxLength={a.name === "contact" ? 10 : 200} />
                                </div>
                            ))
                        }
                    </div>

                </div>
                <small>If you're having trouble adding this document, you can skip it for now, contact customer support, and fill it out later.</small>
                <div className={styles.buttons}>
                    <button disabled={isLoading} type='button' className={styles.backBtn} onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faAngleLeft} color='black' />
                    </button>
                    <div style={{ flex: 1 }}></div>
                    <button type='button' disabled={isLoading} className={styles.skipBtn} onClick={() => navigate('/driver/add-bank')}>
                        Skip
                    </button>
                    <button type='submit' disabled={isLoading} className={styles.submitBtn}>
                        Next
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>
            </form>
            {
                error &&
                <DialogAlert
                    title={error.title}
                    description={error.description}
                    nobutton={{
                        label: 'Close',
                        onPress() {
                            setError(null);
                        },
                    }} />
            }
        </div>
    )
}

export default Step5