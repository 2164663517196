import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './Files.module.css'
import DynamicButton from '../../../components/dynamicbuton/DynamicButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp, faPlus, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import axios from 'axios'
import DialogContainer from '../../../components/dialogcontainer/DialogContainer'
import AlertMessage from '../../../components/alertmessage/AlertMessage'
import { compressImageToTargetSize, compressPDF } from '../../../fileCompression'

const initialFiles = [
    { title: "Passport Photo", description: "Password Photo should be in Image format and should not exceed 2 mb", name: "passport" },
    { title: "PAN", description: "Permanent Account Number should be in PDF format and should not exceed 2 mb", name: "pan" },
    { title: "Driving License", description: "Driving License should be in PDF format and should not exceed 2 mb", name: "driving_license" },
    { title: "Address Proof", description: "Address Proof should be in PDF format and should not exceed 2 mb", name: "prc" },
    { title: "Bank Account", description: "Bank Account should be in PDF format and should not exceed 2 mb", name: "bank" },
    { title: "EPIC", description: "EPIC(Voter ID/Aadhaar) should be in PDF format and should not exceed 2 mb", name: "epic" },
    { title: "Vehicle RC", description: "Vehicle Registration Certificate should be in PDF format and should not exceed 2 mb", name: "vehicle_rc" },
    { title: "Vehicle Permit", description: "Vehicle Permit should be in PDF format and should not exceed 2 mb", name: "vehicle_permit" },
    { title: "Vehicle Fitness", description: "Vehicle Fitness should be in PDF format and should not exceed 2 mb", name: "vehicle_fitness" }
]

interface FormData {
    [key: string]: string;
}


type AlertType = {
    type: 'error' | 'success', message: string
}


const Files = () => {
    const [files, setFiles] = useState<FormData[]>(initialFiles);
    const [emptyFiles, setEmptyFiles] = useState<any[]>([]);
    const [alert, setAlert] = useState<AlertType | null>(null);

    useEffect(() => {
        fetchFiles();
    }, []);

    useEffect(() => {
        console.log(emptyFiles.length);
    }, [emptyFiles])



    const fetchFiles = async () => {
        const token = localStorage.getItem("token");
        const baseUrl = process.env.REACT_APP_API_URL;
        console.log(baseUrl);
        if (token) {
            const parsedToken = JSON.parse(token);
            try {
                const response = await axios.get(
                    baseUrl + "/api/documents",
                    {
                        headers: {
                            Authorization: `Bearer ${parsedToken}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                const files = response.data.document_files;
                mergeData(files);
                console.log(response);
            } catch (error: any) {
                const err = error?.response?.data;
                if (err && err.code === "token_error") {
                    window.location.replace("/driver/signin");
                } else {
                    const errorMessage = err?.errorMessage || "Internal Server Error!";
                    showAlert({ type: "error", message: errorMessage });
                }
            }
        } else {
            window.location.replace("/driver/signin");
        }
    };

    const mergeData = (data: any) => {
        const merged = files.map(item => {
            const additionalItem = data.find((add: any) => add.document_type === item.name);
            return {
                ...item,
                value: additionalItem ? additionalItem.document_type : '',
                path: additionalItem ? additionalItem.path : '',
            };
        });
        setFiles(merged);
    };

    const handleDone = () => {
        const emptyList = files.filter(i => !i.value && !i.path);
        if (emptyList.length > 0) return setEmptyFiles(emptyList);
        window.location.href = "/driver";
    }

    const handleNext = () => {
        window.location.href = "/driver";
    }


    const showAlert = (data: AlertType) => {
        setAlert({ type: data.type, message: data.message })
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    }


    return (
        <div className={styles.page}>
            {
                alert &&
                <AlertMessage type={alert.type} message={alert.message} />
            }
            <h1>Upload files</h1>
            <div className={styles.section}>
                {
                    files.map((file, index) => (
                        <UploadElement key={index} title={file.title} description={file.description} name={file.name} value={file.value} path={file.path} />
                    ))
                }
            </div>
            <p className={styles.notice}><strong>Notice: </strong>Please ensure you fill out all necessary documents carefully; any mistakes will require you to visit our office to resubmit.</p>
            <DynamicButton label='Done' isHaveBackground={true} isRounded={false} onClick={handleDone} />
            {
                emptyFiles.length !== 0 &&
                <DialogContainer>
                    <div className={styles.alertDialog}>
                        <DynamicButton icon={faXmark} isHaveBackground={true} isRounded={true} onClick={() => setEmptyFiles([])} />
                        <div className={styles.emptylist}>
                            {
                                emptyFiles.map((e, index) => (
                                    <p key={index}>{e.title} is not uploaded yet!</p>
                                ))
                            }
                        </div>
                        <DynamicButton label='Continue' isHaveBackground={true} isRounded={false} onClick={handleNext} />
                    </div>
                </DialogContainer>
            }
        </div>
    )
}

interface UploadElementProps {
    title: string,
    description: string,
    name: string,
    value?: string,
    path?: string
}

const UploadElement: React.FC<UploadElementProps> = ({ title, description, name, value, path }) => {
    const [pickedFile, setPickFile] = useState<File | null>(null);
    const [uploadStatus, setUploadStatus] = useState<'' | 'uploading' | 'uploaded' | 'failed' | 'deleting'>('');
    const [uploadedPath, setUploadedPath] = useState<string | null>(null);
    const [alert, setAlert] = useState<AlertType | null>(null);

    useEffect(() => {
        if (value && path) {
            setUploadedPath(path);
            setUploadStatus('uploaded');
        }
    }, [value, path])

    const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        console.log("Original File: ", file);
        if (file && file.size <= 3 * 1024 * 1024) {
            if (name === "passport") {
                if (file.type === "image/jpeg") {
                    const newFile = await compressImageToTargetSize(file, 1);
                    setPickFile(newFile);
                    console.log("Image: ", newFile);
                    saveToServer({ name: title, type: name, file: newFile })
                } else {
                    showAlert({ type: 'error', message: "Only image file is accepted!" })
                }
            } else {
                if (file.type === "application/pdf") {
                    const newFile = await compressPDF(file, 1);
                    console.log("PDF: ", newFile);
                    setPickFile(newFile);
                    saveToServer({ name: title, type: name, file: newFile })
                } else {
                    showAlert({ type: 'error', message: "Only pdf file is accepted!" });
                }

            }
        } else {
            showAlert({ type: 'error', message: "File should not exceed 2 mb!" });
        }
    }

    const showAlert = (data: AlertType) => {
        setAlert({ type: data.type, message: data.message })
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    }

    const saveToServer = async (form: any) => {
        const token = localStorage.getItem("token");
        if (token) {
            const parsedToken = JSON.parse(token);
            const baseUrl = process.env.REACT_APP_API_URL;
            try {
                if (!parsedToken) throw new Error("Token is missing!");
                setUploadStatus('uploading');
                const response = await axios.post(baseUrl + "/api/documents/file", form,
                    {
                        headers: {
                            Authorization: `Bearer ${parsedToken}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log(response);
                setUploadStatus('uploaded');
                const path = response.data?.path;
                // if (path) setUploadedPath(path);
                window.location.reload();
            } catch (error: any) {
                let err = error?.response?.data;
                setUploadStatus('failed');
                if (err && err.code === "token_error") {
                    window.location.replace("/driver/signin");
                }
            }
        }

    };

    const removeFile = async () => {
        const token = localStorage.getItem("token");
        if (!token || !uploadedPath) {
            console.error("Token or uploadedPath is missing!");
            return;
        }

        const parsedToken = JSON.parse(token);

        if (!parsedToken) {
            console.error("Parsed token is invalid!");
            return;
        }

        try {
            setUploadStatus('deleting');
            const baseUrl = process.env.REACT_APP_API_URL;
            const response = await axios.delete(baseUrl +
                `/api/documents/file?type=${name}&path=${uploadedPath}`,
                {
                    headers: {
                        Authorization: `Bearer ${parsedToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log(response);
            setUploadStatus('');
            window.location.reload();
        } catch (error: any) {
            console.error("Error removing file:", error);

            const err = error?.response?.data;
            if (err && err.code === "token_error") {
                window.location.replace("/driver/signin");
            } else {
                console.error("An unexpected error occurred:", err);
            }
            setUploadStatus('failed');
        }
    };


    const getStatusText = () => {
        switch (uploadStatus) {
            case "uploaded":
                return "Uploaded";
            case "failed":
                return "Failed!"
            default:
                return ""
        }
    }

    const fileAcceptType = name === "passport" ? "image/jpeg" : "application/pdf";

    return (
        <form className={styles.form}>
            {
                alert &&
                <AlertMessage type={alert.type} message={alert.message} />
            }
            <div className={styles.heading}>
                <h4>{title}</h4>
                <p>{description}</p>
                {
                    getStatusText() &&
                    <p className={`${styles.statusText} ${uploadStatus === 'failed' ? styles.failed : ''} ${uploadStatus === 'uploaded' ? styles.uploaded : ''}`}>{getStatusText()}</p>
                }

            </div>
            {/* <p>{pickedFile?.name}</p> */}
            {
                uploadStatus === '' &&
                <label className={styles.pickBtn} htmlFor={name}>
                    <FontAwesomeIcon icon={faCloudArrowUp} />
                    <span>Upload</span>
                </label>
            }

            {
                uploadStatus === 'failed' &&
                <label className={styles.pickBtn} htmlFor={name}>
                    <FontAwesomeIcon icon={faCloudArrowUp} />
                    <span>Retry</span>
                </label>
            }

            {
                uploadStatus === 'uploading' &&
                <div className={styles.pickedContainer}>
                    <p className={styles.pickedValue}>{pickedFile?.name}</p>
                    <p className={styles.pickBtn}>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        <span>Uploading...</span>
                    </p>
                </div>
            }

            {
                uploadStatus === 'deleting' &&
                <div className={styles.pickedContainer}>
                    <p className={styles.pickedValue}>{pickedFile?.name}</p>
                    <p className={styles.pickBtn}>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        <span>Deleting...</span>
                    </p>
                </div>
            }

            {
                uploadStatus === 'uploaded' &&
                <div className={styles.pickedContainer}>
                    <p className={styles.pickedValue}>{pickedFile?.name}</p>
                    <button type='button' className={styles.removeBtn} onClick={removeFile}>
                        <FontAwesomeIcon icon={faTrashCan} />
                        <span>Remove</span>
                    </button>
                </div>

            }
            <input type="file" id={name} accept={fileAcceptType} style={{ display: "none" }} onChange={handleInputChange} />
        </form>
    )
}

export default Files