import React from 'react'
import styles from './DialogAlert.module.css'

interface DialogAlertProps {
    title?: string;
    description?: string;
    yesbutton?: {
        label: string;
        onPress: () => void
    },
    nobutton?: {
        label: string;
        onPress: () => void
    }
}

const DialogAlert = ({ title, description, yesbutton, nobutton }: DialogAlertProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                {title && <h3>{title}</h3>}
                {description && <p>{description}</p>}
                <div className={styles.buttons}>
                    {nobutton &&
                        <button
                            className={styles.nobutton}
                            onClick={() => {
                                if (nobutton) nobutton?.onPress()
                            }}>{nobutton.label}
                        </button>}
                    {nobutton && yesbutton && <div className={styles.divider} />}
                    {yesbutton &&
                        <button
                            className={styles.yesbutton}
                            onClick={() => {
                                if (yesbutton) yesbutton?.onPress()
                            }}>{yesbutton.label}
                        </button>}
                </div>
            </div>
        </div>
    )
}

export default DialogAlert