import React, { useEffect, useRef, useState } from 'react'
import styles from './VerifyOtp.module.css'
import axios from 'axios';
import DynamicButton from '../dynamicbuton/DynamicButton';
import DialogContainer from '../dialogcontainer/DialogContainer';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface VerifyOtpProps {
    mobile: string,
    token: string,
    onResend: () => void,
    onError: (message: string) => void,
    onClose: () => void,
    onVerified: (verifiedToken: string) => void,
}

const VerifyOtp = ({ mobile, token, onResend, onError, onClose, onVerified }: VerifyOtpProps) => {
    const [otp, setOtp] = useState<string[]>(Array(4).fill(''));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [isResending, setResending] = useState<boolean>(false);

    const handleChange = (value: string, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = value.replace(/\D/g, '');
        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
        setOtp(newOtp);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        let finalOtp = otp.filter(o => o !== "");
        if (finalOtp.length !== 4) return;
        const myString = otp.join('');
        verifyOtp({ mobile, otp: myString });
    };

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);



    const verifyOtp = async (form: any) => {
        const baseUrl = process.env.REACT_APP_API_URL;

        if (!mobile) {
            onError("Mobile number not found!")
            return;
        }
        try {
            const response = await axios.post(baseUrl + `/api/auth/verify-otp`, form,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const newToken = response.data.token;
            onVerified(newToken);

        } catch (error: any) {
            const errorMessage = error.response?.data?.errorMessage || "Internal Server Error!";
            if (errorMessage === "exist") {
                onError("exist");
                return
            }
            onError(errorMessage)
        }
    };


    return (
        <DialogContainer>
            <div className={styles.spacer}></div>
            <div className={styles.section}>
                <div className={styles.heading}>
                    <h1>OTP Verification</h1>
                    <DynamicButton icon={faXmark} isHaveBackground={true} isRounded={true} onClick={onClose} />
                </div>
                <div>
                    <small>OTP has sent to {mobile}</small>
                    {/* <button className={styles.changeBtn} onClick={() => setPhoneChanging(true)}>Change phone</button> */}
                </div>

                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.otp}>
                        {otp.map((digit, index) => (
                            <input
                                className={styles.input}
                                key={index}
                                type="text"
                                value={digit}
                                onChange={(e) => handleChange(e.target.value, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                maxLength={1}
                                ref={(ref) => (inputRefs.current[index] = ref)}
                            />
                        ))}
                    </div>
                    <DynamicButton buttonType={'submit'} label='Verify' isHaveBackground={true} isRounded={false} />
                </form>
                <div className={styles.noOtp}>
                    <p>Haven't got OTP yet?</p>
                    {
                        !isResending ? <button onClick={onResend}>Resend</button> : <p>Resending OTP....</p>
                    }
                </div>
            </div>
            <div className={styles.spacer}></div>
        </DialogContainer>
    )
}

export default VerifyOtp