import React from 'react'
import styles from './AlertMessage.module.css'
import DynamicButton from '../dynamicbuton/DynamicButton';

interface AlertMessageProps {
    type: 'error' | 'success',
    message: string;
    buttonLabel?:string;
    onButtonClick?: () => void
}

const AlertMessage = ({ type, message, buttonLabel,onButtonClick }: AlertMessageProps) => {
    return (
        <div className={`${styles.container} ${type == 'error' ? styles.error : ''}`}>
            <p>{message}</p>
            {
                buttonLabel && onButtonClick && 
                <DynamicButton label={buttonLabel} isHaveBackground={true} isRounded={true} onClick={onButtonClick} />
            }
        </div>
    )
}

export default AlertMessage