import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import styles from './Step4.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import SelectInput from '../../../../components/inputs/selectInput/SelectInput'
import StandardInput from '../../../../components/inputs/standardInput/StandardInput'
import axios from 'axios'
import { ErrorType } from '../step1/Step1'
import DialogAlert from '../../../../components/dialogalert/DialogAlert'
import { useRegistration } from '../../../../contexts/RegistrationContext'
import SEO from '../../../../SEO'

const vehicleTypes = [
    { label: "--Select vehicle type--", value: '' },
    { label: "Two Wheeler", value: 'Two Wheeler' },
    { label: "Hatchback", value: 'Hatchback' },
    { label: "Sedan", value: 'Sedan' },
    { label: "SUV", value: 'SUV' }
];

const fieldss = [
    { label: "Name & model", name: "cab_model", inputType: "text", placeholder: "" },
    { label: "Color", name: "cab_colour", inputType: "text", placeholder: "" },
    { label: "Registration Certificate(RC) number", name: "cab_number", inputType: "text", placeholder: '' },
    { label: "Vehicle Type", name: "medium_id", inputType: "select", options: [] },
];


const Step4 = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { vehicle, setVihicle } = useRegistration();
    const [error, setError] = useState<ErrorType | null>(null);
    const throttle = useRef<boolean>(false);
    const [fields, setFields] = useState<any[]>([]);

    useEffect(() => {
        if (!throttle.current) {
            setFields(fieldss);
            fetchMediums();
            throttle.current = true
        }
    }, []);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setVihicle({ ...vehicle, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(vehicle);
        if (!vehicle.cab_model) return setError({ title: 'Error!', description: "Vehicle name & model is missing!" });
        if (!vehicle.cab_colour) return setError({ title: 'Error!', description: "Vehicle color is missing!" });
        if (!vehicle.cab_number) return setError({ title: 'Error!', description: "Vehicle number is missing!" });
        if (!vehicle.medium_id) return setError({ title: 'Error!', description: "Vehicle type is missing!" });
        saveToServer(vehicle);
    };


    const saveToServer = async (form: any) => {
        const token = localStorage.getItem("token");
        if (token) {
            const baseUrl = process.env.REACT_APP_API_URL;
            setIsLoading(true);
            try {
                const response = await axios.post(baseUrl + "/api/documents?docType=cabs", form,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log(response.data);
                navigate('/driver/add-family-contacts');
                // window.location.replace('/driver/add-family-contacts');
            } catch (error: any) {
                let err = error?.response?.data.errorMessage || "Internal server error";
                console.log(err);
                setError({ title: 'Error occured!', description: err });
                // if (err && err.code === "token_error") {
                //     window.location.replace("/driver/signin");
                // }
            } finally {
                setIsLoading(false)
            }
        } else {
            console.error("Token not available!");
            setError({ title: 'Error occured!', description: "Token not available!" });
        }
    };

    const fetchMediums = async () => {
        const baseUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await axios.get(baseUrl + "/api/documents/medium");
            const resData = response.data.mediums;
            if (resData) {
                const mediums = resData.map((e: any) => ({ label: e.medium_type, value: e.medium_id }));
                setFields(prevList =>
                    prevList.map(obj =>
                        obj.name === "medium_id" ? { ...obj, options: mediums } : obj
                    )
                );
            }
        } catch (error: any) {
            let err = error?.response?.data.errorMessage || "Internal server error";
            console.log(err);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.section}>
            <SEO title='Add vehicle - Driver' description='Add vehicle page for driver registration' url='https://uracab.com/driver/add-vehicle' image='https://uracab.com/services.jpeg' />

            <h1>Add your vehicle</h1>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formSection}>
                    {fields.map((field) => {
                        return field.inputType === "select" ? (
                            <SelectInput
                                key={field.name}
                                id={field.name}
                                label={field.label}
                                name={field.name}
                                value={vehicle[field.name] || ''}
                                options={[{ label: '--Select type--', value: '' }, ...(field.options || [])]}
                                onChange={handleInputChange}
                            />
                        ) : (
                            <StandardInput
                                key={field.name}
                                id={field.name}
                                label={field.label}
                                name={field.name}
                                value={vehicle[field.name] || ''}
                                inputType={field.inputType}
                                placeHolder={field.placeholder}
                                onChange={handleInputChange}
                            />
                        );
                    })}
                </div>
                <small>If you're having trouble adding this document, you can skip it for now, contact customer support, and fill it out later.</small>
                <div className={styles.buttons}>
                    <button
                        type="button"
                        className={styles.backBtn}
                        disabled={isLoading}
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} color="black" />
                    </button>
                    <div style={{ flex: 1 }}></div>
                    <button
                        type="button"
                        className={styles.skipBtn}
                        disabled={isLoading}
                        onClick={() => {
                            navigate('/driver/add-family-contacts');
                        }}
                    >
                        Skip
                    </button>
                    <button
                        type="submit"
                        className={styles.submitBtn}
                        disabled={isLoading}
                    >
                        Next
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>
            </form>
            {
                error &&
                <DialogAlert
                    title={error.title}
                    description={error.description}
                    nobutton={{
                        label: 'Close',
                        onPress() {
                            setError(null);
                        },
                    }} />
            }
        </div>
    );
};

export default Step4;
