import React from 'react'
import styles from './Drivers.module.css'
import DynamicButton from '../../components/dynamicbuton/DynamicButton'


const sections = [
  { title: "Flexible Hours", description: "Choose when and how often you drive, giving you complete control over your schedule." },
  { title: "Get Paid Quickly", description: "Enjoy fasty payments directly to your bank account, so you’re rewarded promptly for your efforts." },
  { title: "Support at Every Turn", description: "Reach our dedicated support team anytime you need assistance your success is ¸our priority!" },
]

const requirements = [
  {
    title: "Qualifications",
    list: [
      'Valid Motorcycles both with and without gear.',
      'Valid light motor vehicle license.',
      'Nagaland residency.',
      'Clean criminal record.',
      'KYC-compliant bank account.'
    ]
  },
  {
    title: "Vehicle Requirements",
    list: [
      'Fitness Certificate: Must hold a valid Fitness Certificate issued under the Motor Vehicles Act, 1988.',
      'Relevant Permit: Required to possess the appropriate permit to operate in the designated area.',
      'Safety Compliance: Must comply with safety requirements, including a vehicle tracking system (provided by UraCab).',
      'Emission Standards: The vehicle must meet current emission standards and have a valid Pollution under Control Certificate from the Nagaland Pollution Control Board.',
      'Insurance Coverage: Must include chassis and engine numbers, along with a commercial insurance policy covering third-party risks.'
    ]
  },
  {
    title: "Required Documents",
    list: [
      'A photograph of the driver',
      'Driving license',
      'Present home address with proof of residential address',
      'RBI compliance KYC bank account details. (Any active bank account preferably SBI Bank, Lerei Branch)',
      'Self-attested copies of EPIC card and PAN Card.',
      'Contact details and addresses of two family members.',
      'Registration Certificate (RC) of the vehicle.',
      'Fitness Certificate of the vehicle.',
      'Permit of the vehicle.'
    ]
  },
]

const Drivers = () => {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.downloadContainer}>
          <p style={{ fontWeight: '500', margin: 0 ,fontSize:'x-large',color:'white'}}>Download Driver App</p>
          <button className={styles.downloadBtn} onClick={() => window.location.href = "/download/android"}>Download</button>
        </div>
        <div className={styles.feature}>
          <img className={styles.image} src="/taxi.jpeg" alt="" />
          <div className={styles.featureTexts}>
            <h1>
              Earn at your leisure, your way.
            </h1>
            <p>Say hello to a career that’s designed for flexibility. Drive
              when you want and earn as much as you need. Whether
              it’s a side hustle or your main income, we give you the
              freedom to choose. With peak-time pricing, your hard
              work gets rewarded whenever you’re ready.</p>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionList}>
            {
              sections.map((section, index) => (
                <div key={index} className={styles.sectionItem}>
                  <h3>{section.title}</h3>
                  <p>{section.description}</p>
                </div>
              ))
            }
          </div>
          {
            requirements.map((requiement, index) => (
              <div key={index} className={styles.sectionList2}>
                <h3>{requiement.title}</h3>
                <div className={styles.list}>
                  {
                    requiement.list.map((section, index) => (
                      <div>
                        <p>{index + 1}. </p>
                        <p key={index}>{section}</p>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
          <div className={styles.buttonContainer}>
            <p>All set for your UraCab journey?</p>
            <DynamicButton label='Register' isHaveBackground={true} isRounded={true} onClick={() => {
              window.location.href = "/driver/send-otp"
            }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Drivers