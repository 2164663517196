import React, { ChangeEvent, useState } from 'react'
import styles from './Step1.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import DialogAlert from '../../../../components/dialogalert/DialogAlert'
import SEO from '../../../../SEO'

export type ErrorType = {
    title: string;
    description: string;
}

const Step1 = () => {
    const navigate = useNavigate();
    const [isLoading, setsIsLoading] = useState<boolean>(false);
    const [mobile, setMobile] = useState<string>('');
    const [error, setError] = useState<ErrorType | null>(null);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) return;
        setMobile(value);
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isGo()) {
            saveToServer({ mobile });
        }
    }

    const isGo = () => {
        if (!mobile || mobile.length !== 10) return false
        return true
    }



    const saveToServer = async (form: any) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        setsIsLoading(true)
        try {
            const response = await axios.post(baseUrl + "/api/auth/send-otp", form);
            const token = response.data?.token;
            console.log(token);
            localStorage.setItem('authToken', token);
            navigate('/driver/verify-otp?mobile=' + form.mobile);
        } catch (error: any) {
            let err = error?.response?.data.errorMessage || "Internal server error";
            console.log(err);
            setError({ title: 'Error occured!', description: err });
        } finally {
            setsIsLoading(false)
        }

    };
    return (
        <div className={styles.page}>
            <SEO title='Registration - Driver' description='Send otp page for driver registration' url='https://uracab.com/driver/send-otp' image='https://uracab.com/services.jpeg' />
            <div className={styles.spacer}></div>
            <div className={styles.section}>
                <h1>Enter mobile number</h1>
                <form className={styles.form} onSubmit={onSubmit}>
                    <div className={styles.inputContainer}>
                        <label htmlFor="phone">+91</label>
                        <input type="text" placeholder='enter 10 digits mobile number' value={mobile} onChange={handleInputChange} disabled={isLoading} maxLength={10} />
                    </div>
                    <small className={styles.phoneNotice}>Please ensure your mobile number is correct, as it will be used for logging in.</small>
                    <div className={styles.buttons}>
                        <button disabled={isLoading} type='button' className={styles.backBtn} onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={faAngleLeft} color='black' />
                        </button>
                        <button type='submit' disabled={isLoading || !isGo()} className={styles.submitBtn}>
                            Next
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                    <p className={styles.signIn}>Already registered? <a href='/driver/signin'>Signin</a></p>
                </form>
            </div>
            {
                error &&
                <DialogAlert
                    title={error.title}
                    description={error.description}
                    nobutton={{
                        label: 'Close',
                        onPress() {
                            setError(null);
                        },
                    }} />
            }

            <div className={styles.spacer}></div>
        </div>
    )
}

export default Step1