import React from 'react'
import styles from './BottomFooters.module.css'

function BottomFooters() {
    const currentYear = new Date().getFullYear();
    return (
        <section className={styles.container}>
            <p className={styles.copyright}>
                &copy; {currentYear} UraCab Private Limited. All rights reserved.
            </p>
            <ul className={styles.links}>
                {/* <li>
                    <a className={styles.link} href="/privacy&policies">Privacy Policies</a>
                </li> */}

                <li>
                    <a className={styles.link} href="https://sites.google.com/view/uracabprivacypolicy/home">Privacy Policies - Driver</a>
                </li>
                {/* <div className={styles.divider}></div> */}
                <li>
                    <a className={styles.link} href="https://sites.google.com/view/uracab-user-privacy-policies/home">Privacy Policies - User</a>
                </li>
                {/* <div className={styles.divider}></div> */}
                {/* <li>
                    <a className={styles.link} href="/termsofuse">Terms of use</a>
                </li> */}
                <li>
                    <a className={styles.link} href="https://sites.google.com/view/terms-condition-uracab-driver/home">Terms & Conditions - Driver</a>
                </li>
                {/* <div className={styles.divider}></div> */}
                <li>
                    <a className={styles.link} href="https://sites.google.com/view/termscondition-uracabuser/home">Terms & Conditions - User</a>
                </li>
            </ul>
        </section>
    )
}

export default BottomFooters