// context/RegsistrationContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";
import { fields } from "../pages/drivers/resgistration/step7/Step7";

interface RegistrationContextType {
    profile: Record<string, string>;
    setProfile: (data: Record<string, string>) => void;
    vehicle: Record<string, string>;
    setVihicle: (data: Record<string, string>) => void;
    familyContacts: Record<string, string>;
    setFamilyContacts: (data: Record<string, string>) => void;
    bank: Record<string, string>;
    setBank: (data: Record<string, string>) => void;
    files: FileForm[];
    setFiles: (data: FileForm[]) => void;
}

enum UploadStatus {
    Initial = 'initial',
    Loading = 'loading',
    Failed = 'failed',
    Loaded = 'loaded',
}

interface FileForm {
    document_name: string;
    document_type: string;
    description: string;
    path?: string;
    status?: UploadStatus;
}

const RegsistrationContext = createContext<RegistrationContextType | undefined>(undefined);

interface RegistrationProviderProps {
    children: ReactNode;
}

export const RegistrationProvider: React.FC<RegistrationProviderProps> = ({ children }) => {
    const [profile, setProfile] = useState<Record<string, string>>({});
    const [vehicle, setVihicle] = useState<Record<string, string>>({});
    const [familyContacts, setFamilyContacts] = useState<Record<string, string>>({});
    const [bank, setBank] = useState<Record<string, string>>({});
    const [files, setFiles] = useState<FileForm[]>(fields);

    return (
        <RegsistrationContext.Provider value={{ profile, setProfile, vehicle, setVihicle, familyContacts, setFamilyContacts, bank, setBank, files, setFiles }}>
            {children}
        </RegsistrationContext.Provider>
    );
};

export const useRegistration = (): RegistrationContextType => {
    const context = useContext(RegsistrationContext);
    if (!context) {
        throw new Error("useProfile must be used within a Registration");
    }
    return context;
};
