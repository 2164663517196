import React from 'react'
import styles from './DialogContainer.module.css'

const DialogContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className={styles.container}>
            <div className={styles.spacer}></div>
            {children}
            <div className={styles.spacer}></div>
        </div>
    )
}

export default DialogContainer