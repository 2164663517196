import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './Driver.module.css'
import DynamicButton from '../../components/dynamicbuton/DynamicButton';
import { faEnvelope, faPhone, faRightFromBracket, faSpinner, faStar, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { ErrorType } from '../drivers/resgistration/step1/Step1';
import { useRegistration } from '../../contexts/RegistrationContext';
import SEO from '../../SEO';

const docs = [
  { label: "VEHICLE", url: "/driver/add-vehicle", name: 'cabs' },
  { label: "FAMILY CONTACTS", url: '/driver/add-family-contacts', name: 'family_contacts' },
  { label: "BANK", url: "/driver/add-bank", name: 'bank' },
  { label: "FILES", url: "/driver/upload-files", name: 'bank' },
];

export const fields = ['passport', 'pan', 'driving_license', 'prc', 'bank', 'vehicle_rc', 'vehicle_fitness', 'vehicle_permit', 'epic'];

const DriverDashboard = () => {
  const { id } = useParams();
  const [loading, setIsLoading] = useState<boolean>(true);
  // const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const { files, profile, setProfile, setVihicle, setBank, setFamilyContacts, setFiles } = useRegistration();
  const throttle = useRef<boolean>(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (!throttle.current) {
      fetchData();
      throttle.current = true
    }
  }, [])

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_API_URL;

    if (!token) {
      // handleTokenError();
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/documents/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setData(response.data);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errorMessage || "Internal server error";
      setError({ title: "Error occurred!", description: errorMessage });
    } finally {

    }
  };

  const setData = (data: any) => {
    console.log(data);
    if (data.profile) {
      localStorage.setItem('user', JSON.stringify(data.profile));
      setProfile(data.profile);
    }
    if (data.cabs) {
      setVihicle(data.cabs);
    } else {
      navigate('/driver/add-vehicle');
      return
    }

    if (data.family_contacts) {
      setFamilyContacts(data.family_contacts);
      setIsLoading(false);
    } else {
      navigate('/driver/add-family-contacts');
      return
    }

    if (data.bank) {
      setBank(data.bank)
    } else {
      navigate('/driver/add-bank');
      return
    }

    if (data.files) {
      setFiles(data.files)
      if (!data.files.includes(fields)) {
        // navigate('/driver/upload-files')
      }
    }
    setIsLoading(false);
  }

  const getFile = (fileType: string, key: string) => {
    const file: any = files.find(f => f.document_type === fileType);
    if (file) {
      return file[key];
    } else {
      return null
    }
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  }


  return loading ? (
    <FontAwesomeIcon className={styles.loading} icon={faSpinner} spin />
  ) : (
    <div className={styles.container}>
      <SEO title={profile.driver_name || 'Profile - Driver'} description='Profile page of driver' url='https://uracab.com/driver' image='https://uracab.com/services.jpeg' />

      <div className={styles.spacer}></div>
      <div className={styles.section}>
        <img className={styles.profilePicture} src={'https://uragate.in/websites/webapi4/file/' + getFile('passport', 'path')} alt="" />
        <div className={styles.nameContact}>
          <h2>{profile.driver_name}</h2>
          <h3>ID: {profile.driver_ID}</h3>
          <div className={styles.contacts}>
            <p>
              <FontAwesomeIcon icon={faPhone} />
              <span>{profile.driver_contact}</span>
            </p>
            {
              profile.driver_email &&
              <p>
                <FontAwesomeIcon icon={faEnvelope} />
                <span>{profile.driver_email}</span>
              </p>
            }
          </div>
        </div>
        {/* <div className={styles.driverPerformance}>
        <div>
          <p>100</p>
          <p>Total trips</p>
        </div>

        <div>
          <p>5<FontAwesomeIcon icon={faStar} /></p>
          <p>Ratings</p>
        </div>

        <div>
          <p style={{ color: "darkgreen" }}>Active</p>
          <p>Status</p>
        </div>
      </div> */}

        <div className={styles.docsContainer}>
          <h3>Documents</h3>
          <div className={styles.docs}>
            {
              docs.map((menu, index) => (
                <Link className={styles.menu} to={menu.url} key={index}>{menu.label}</Link>
              ))
            }
          </div>
        </div>
        <button className={styles.logoutBtn} onClick={handleLogout}>Logout</button>
      </div>
      <div className={styles.spacer}></div>
    </div>
  )
}

export default DriverDashboard