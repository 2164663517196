import React, { useState } from 'react'
import styles from './FooterLinks.module.css'
import { links, products, supports, travels } from '../../data'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Address from '../address/Address'


function FooterLinks() {
    const [selectedSection, setSelectedSection] = useState<string>("");
    const handleSectionSelected = (name: string) => {
        if (selectedSection == name) {
            setSelectedSection("");
        } else {
            setSelectedSection(name);
        }
    }
    return (
        <div className={styles.container}>
            <Links title="Company" urls={links} />
            <Links title="Products" urls={products} />
            <Links title="Travels" urls={travels} />
            <Links title="Support" urls={supports} />
            <Address />
        </div>
    )
}

interface LinksProps {
    title: string,
    urls: any[],
}
const Links: React.FC<LinksProps> = ({ title, urls }) => {
    return (
        <section className={styles.section}>
            <h2 className={styles.headerTitle}>{title}</h2>
            <ul className={styles.links}>
                {
                    urls.map((link, index) => (
                        <li key={index}>
                            {
                                ['Travels'].includes(title) ? <p className={styles.link} style={{pointerEvents:'none'}}>{link.label}</p> : <Link to={link.url} className={styles.link}>{link.label}</Link>
                            }
                        </li>
                    ))
                }
            </ul>
        </section>
    )
}
export default FooterLinks